<template>
  <div class="semester-detail">
    <div class="d-flex justify-content-between">
      <h6 class="text-primary">Semester/Term Details</h6>
      <CButton
        class="btn-primary small mr-2"
        style="height: 28px"
        v-c-tooltip="'Click to Add new Semester!'"
        @click="addNewSemester"
        >Add</CButton
      >
    </div>
    <div
      class="mt-2 border-dotted-bottom d-flex justify-content-between"
      v-for="(semester, index) in semesters"
      :key="index"
    >
      <div class="module-record">
        <CRow>
          <CCol md="4">
            <CRow class="mb-3">
              <label class="col-lg-12 col-md-12 required">Semester No.</label>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <TextInput
                    name="semester_no"
                    :value="semester.semester_no"
                    @input="
                      (name, value) => handleInputMulti(name, value, index)
                    "
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </CRow>
          </CCol>
          <CCol md="4">
            <CRow class="mb-3">
              <label class="col-lg-12 col-md-12 required">Start Date</label>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <DatePicker
                    name="start_date"
                    :value="semester.start_date"
                    @input="
                      (name, value) => handleDatePickerMulti(name, value, index)
                    "
                    format="DD-MM-YYYY"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </CRow>
          </CCol>
          <CCol md="4">
            <CRow class="mb-3">
              <label class="col-lg-12 col-md-12">End Date</label>
              <div class="col-lg-12 col-md-12 col-sm-12">
                  <DatePicker
                    name="end_date"
                    :value="semester.end_date"
                    @input="
                      (name, value) => handleDatePickerMulti(name, value, index)
                    "
                    format="DD-MM-YYYY"
                  />
              </div>
            </CRow>
          </CCol>
          <CCol md="12">
            <CRow class="mb-3">
              <label class="col-lg-12 col-md-12">Description</label>
              <div class="col-lg-12 col-md-12">
                <TextAreaInput
                  name="description"
                  :value="semester.description"
                  @input="(name, value) => handleInputMulti(name, value, index)"
                />
              </div>
            </CRow>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>
<script>
import m from "moment"
import Vue from "vue";
import courseMixin from "@/containers/Course/AddCourse/courseMixin.js";
export default {
  name: "SemesterDetail",
  mixins: [courseMixin],
  methods: {
    updateParentData() {
      const formName = "semesters";
      this.$emit("syncData", formName, this.payload[formName]);
    },
    handleInputMulti(name, value, index) {
      Vue.set(this.semesters[index], name, value);
      let val = value;
      if(name === "semester_no" && value){
        val = parseInt(val)
      }
      this.payload.semesters[index][name] = val;
      this.updateParentData();
    },
    handleDatePickerMulti(name, value, index) {
      const val = m(value).format("YYYY-MM-DD");
      Vue.set(this.semesters[index], name, value);
      this.payload.semesters[index][name] = val;
      this.updateParentData();
    },
  },
};
</script>
